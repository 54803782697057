<template>
  <div>
    <div>
      <div style="width: 95%;margin: auto">
        <el-table :data="tableData" border style="width: 100%"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column type="index" :index="indexMethod"/>
          <el-table-column prop="questionTitle" label="问题" width="180"/>
          <el-table-column prop="questionUser" label="提问人" width="180"/>
          <el-table-column prop="questionTime" label="提问时间"/>
          <el-table-column prop="answer" label="回答内容"/>
          <el-table-column prop="answerUser" label="回答人" width="180"/>
          <el-table-column prop="answerTime" label="回答时间"/>
          <el-table-column prop="answerrespondtime" label="回答所耗时"/>
          <el-table-column prop="answernum" label="回答次数"/>
          <el-table-column prop="b" label="操作">
            <template #default="scope">
              <el-button style="margin-left: 1vh" type="primary" size="mini" @click="getAnswer(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!--  问题附件详情-->
    <div>
      <el-dialog
          v-model="questionMaterialDialog"
          title="问题附件"
          width="50%"
      >
        <!--问题附件-->
        <div v-for="item in materialUrl">
          <img v-if="item.questionMaterialUrl.endsWith('jpg')" :src="item.questionMaterialUrl"
               style="width: 500px;height: 500px"/>
          <img v-if="item.questionMaterialUrl.endsWith('png')" :src="item.questionMaterialUrl"
               style="width: 500px;height: 500px"/>
          <video width="500" height="500" controls v-if="item.questionMaterialUrl.includes('mp4')">
            <source :src="item.questionMaterialUrl" type="video/mp4">
          </video>
          <div
              v-if="!item.questionMaterialUrl.includes('jpg') && !item.questionMaterialUrl.includes('png') && !item.questionMaterialUrl.includes('mp4')"
              style="margin-top: 60px"
              @dblclick="openUrl(item.questionMaterialUrl)">
            <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/files.png" style="width: 50px;height: 50px"/>
            <span>{{ item.questionMaterialName }}</span>
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="questionMaterialDialog = false"> 确认 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  回答详情-->
    <div>
      <el-dialog
          v-model="getanswerDia"
          title="回答问题"
          width="50%"
      >
        <!--问题附件-->
        <div>
          <div style="display: flex;margin-bottom: 40px">
            <div style="margin-right: 20px"> 问题描述: </div>
            <div>{{ question }}</div>
          </div>
          <div>
            <el-table :data="answers" border style="width: 100%"
                      :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}">
              <el-table-column type="index" :index="indexMethod"/>
              <el-table-column prop="answer" label="回答内容" width="180"/>
              <el-table-column prop="answerTime" label="回答时间"/>
              <el-table-column prop="answerUser" label="回答人"/>
            </el-table>
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="getanswerDia = false">关闭</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  我要回答弹窗-->
    <div>
      <el-dialog
          v-model="addanswerDia"
          title="回答问题"
          width="50%"
      >
        <!--问题附件-->
        <div>
          <div style="display: flex;margin-bottom: 70px">
            <div style="margin-right: 20px"> 问题描述: </div>
            <div>{{ question }}</div>
          </div>
          <div style="display: flex">
            <div style="margin-right: 20px"> 问题回答: </div>
            <div style="width: 50%">
              <el-input
                  v-model="answer"
                  autosize
                  type="textarea"
                  placeholder="请输入回答"
              />
            </div>
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelAnswer()"> 取消 </el-button>
            <el-button type="primary" @click="submitAnser()"> 提交 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--    分页-->
    <div style="float: right;margin-top: 20px;margin-right: 5%">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";

export default {
  name: "havemyanswer",
  data() {
    return {
      indexMethod: 1,
      tableData: [],
      questionMaterialDialog: false,
      addanswerDia: false,
      getanswerDia: false,
      answer: '',
      question: '',
      question_id: '',
      answers: [],
      pagenum: 1,
      totals: 0
    }
  },
  created() {
    this.getAnswerQuestion();
  },
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
  },
  methods: {
    getAnswerQuestion() {
      this.axios.get('/expertQuestion/getHaveAnswerQuestion', (response) => {
        this.tableData = response.obj;
        this.totals = response.obj2.pages * 10;
      },{
        userid: this.GET_USERID,
        pageCount: 1,
        type: '0'
      })
    },
    getMaterial(row){
      this.questionMaterialDialog = true;
      this.axios.post('/expertQuestionMaterial/getMaterilUrl', (response) => {
        this.materialUrl = response.obj;
      },{
        uuid: row
      })
    },
    addAnswer(val){
      this.question_id = val.id;
      this.question = val.question;
      this.addanswerDia = true;
    },
    cancelAnswer(){
      this.answer = '';
      this.addanswerDia = false;
    },
    submitAnser(){
      this.axios.post('/expertAnswer/submitAnswer', (response) => {
        if (response.obj){
          this.answer = '';
          this.addanswerDia = false;
          this.getNoAnswer();
        }else{
          ElMessage.error("回答失败，请联系科信部!")
        }
      },{
        questionid: this.question_id,
        username: this.GET_USER,
        userid: this.GET_USERID,
        answer: this.answer
      })
    },
    getAnswer(val) {
      console.log(val)
      this.$router.push({
        name: 'Answer_detail',
        params: {
          key: val.id,
          flag: '1',
          question: val.question,
          title: val.questionTitle,
          question_userid: val.question_userid,
          questionUser: val.questionUser,
          questionTime: val.questionTime
        }
      });
    }
  },
  watch: {
    pagenum() {
      this.axios.get('/expertQuestion/getHaveAnswerQuestion', (response) => {
        this.tableData = response.obj;
        this.totals = response.obj2.pages * 10;
      },{
        userid: this.GET_USERID,
        pageCount: this.pagenum,
        type: '0'
      })
    }
  }
}

</script>

<style scoped>

</style>