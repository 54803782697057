<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13em;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 1.2em" :to="{ path: '/expert_index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 1.2em;color: black">有问必答</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--  已回答、未回答、我的提问  -->
    <div style="width: 40%;margin: auto">
      <el-button class="item" @click="getAnswerQuestion('0')" style="width: 15vh;margin-bottom: 1vh">已回答</el-button>
      <el-badge :value="noAnswerNum" class="item">
        <el-button style="width: 15vh;margin-bottom: 1vh" @click="getAnswerQuestion('1')">未回答</el-button>
      </el-badge>

      <el-dropdown trigger="click">
        <el-button class="el-dropdown-link" style="width: 15vh;">我的提问</el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item class="clearfix" @click="getAnswerQuestion('2')">
              已回答
              <el-badge class="mark"/>
            </el-dropdown-item>
            <el-dropdown-item class="clearfix" @click="getAnswerQuestion('3')">
              未回答
              <el-badge class="mark" :value="noMyAnswerNum"/>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

    </div>
    <!--已回答的问题-->
    <div  v-if="tableFlag === '0'">
      <HaveAnswerQuestion></HaveAnswerQuestion>
    </div>
    <!--未回答的问题-->
    <div v-if="tableFlag === '1'">
      <NoAnswerQuestion></NoAnswerQuestion>
    </div>
    <!--我的已回答的问题-->
    <div  v-if="tableFlag === '2'">
      <HaveMyAnswerQuestion></HaveMyAnswerQuestion>
    </div>
    <!--我的未回答的问题-->
    <div v-if="tableFlag === '3'">
      <NoMyAnswerQuestion></NoMyAnswerQuestion>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header1";
import NoAnswerQuestion from "./noanswer";
import HaveAnswerQuestion from "./haveanswer";
import HaveMyAnswerQuestion from "./havemyanswer";
import NoMyAnswerQuestion from "./nomyanswer";
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";

export default {
  inject: ['reload'],
  components: {Header, NoAnswerQuestion, HaveAnswerQuestion, HaveMyAnswerQuestion, NoMyAnswerQuestion},
  name: "index",
  data() {
    return {
      addQuestionDialog: false,
      question: '',
      dataup: {
        file: [],
        userid: ''
      },
      fileList: [],
      expertype: '',
      expertypes: [
        {
          value: '0',
          label: '内部专家',
        },
        {
          value: '1',
          label: '外部专家',
        }
      ],
      expertname: '',
      expertnames: [],
      question_uuid: '',
      questionMaterialDialog: false,
      tableFlag: '0',
      noAnswerNum: '',
      noMyAnswerNum: ''
    }
  },
  created() {
    this.axios.get('/expertQuestion/getNoAnswerQuestion', (response) => {
      this.noAnswerNum = response.obj2.total;
    },{
        pageCount: 1
    });
    this.axios.get('/expertQuestion/getNoAnswerQuestion', (response) => {
      this.noMyAnswerNum = response.obj2.total;
    },{
      userid: this.GET_USERID,
      pageCount: 1
    });
  },
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
  },
  methods: {
    getAnswerQuestion(val){
      this.tableFlag = val;
    },

  }
}

</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}

.el-dropdown {
  margin-top: 1.1rem;
}

.item {
  margin-top: 10px;
  margin-right: 60px;
}
</style>